<template>
  <div class="main-container">
    <div class="product-banner">
      <h1>矩尺网络&nbsp;&nbsp;|&nbsp;&nbsp;产品介绍</h1>
      <div class="title-devide-line"></div>
      <h2>保障用户关键业务的稳定性和可靠性</h2>
      <p>提供多种算法和健康检查方式，实时帮助用户发现问题，并且提供压力卸载和优化加速功能，帮助服务器减轻压力。</p>
    </div>
    <div class="product-body">
      <!-- 产品技术优势 -->
      <div class="product-advantage">
        <div class="product-advantage-bgcover">
          <TitlePoint title="产品技术优势" />
          <div class="product-advantage-content">
            <div class="product-advantage-content-left">
              <img v-show="activeTab === '算法优化'" src="@/assets/imgs/product-advantage-algorithm.png" width="375" alt="算法优化">
              <img v-show="activeTab === '定制化开发'" src="@/assets/imgs/product-advantage-customization.png" width="375" alt="定制化开发" loading="lazy">
              <img v-show="activeTab === '灵活扩展'" src="@/assets/imgs/product-advantage-extension.png" width="375" alt="灵活扩展" loading="lazy">
              <img v-show="activeTab === '提升ROI'" src="@/assets/imgs/product-advantage-ROI.png" width="375" alt="提升ROI" loading="lazy">
            </div>
            <div class="product-advantage-content-right">
              <el-tabs tab-position="bottom" v-model="activeTab" style="height: 200px;">
                <el-tab-pane name="算法优化" label="算法优化">
                  <h3>算法优化</h3>
                  <p>丰富的L4/L7负载算法，可根据不同业务场景灵活搭配使用，大幅提升服务器资源利用率。</p>
                </el-tab-pane>
                <el-tab-pane name="定制化开发" label="定制化开发">
                  <h3>定制化开发</h3>
                  <p>根据应用需求灵活定制分发策略/规则，自定义健康检查，实时监控业务状态，保证故障无缝切换。</p>
                </el-tab-pane>
                <el-tab-pane name="灵活扩展" label="灵活扩展">
                  <h3>灵活扩展</h3>
                  <p>后台服务器节点池化，可根据需求添加节点，负载均衡设备可构建集群，整个业务系统具备良好的可扩展性。</p>
                </el-tab-pane>
                <el-tab-pane name="提升ROI" label="提升ROI">
                  <h3>提升ROI</h3>
                  <p>多种性能优化、访问加速技术，减少服务器性能消耗，降低硬件投资成本，提升用户访问体验。</p>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
      <!-- 产品特点 -->
      <ProductTitle title="产品特点" align="left"/>
      <div class="characteristic-wrap">
        <div class="characteristic">
          <div class="characteristic-item" v-for="item in characteristicList" :key="item.title">
            <div class="title-icon" :class="'icon-' + item.img"></div>
            <h2>{{item.title}}</h2>
            <p>{{item.description}}</p>
          </div>
        </div>
      </div>
      <!-- 产品部署结构 -->
      <ProductTitle title="产品部署结构" align="right"/>
      <div class="structure-wrap">
        <div class="structure">
          <div class="structure-bgcover">
            <div class="structure-item" :class="{'is-detail': activeDetail === '旁路模式'}">
              <div class="icon-deployment"></div>
              <h3>矩尺负载均衡——旁路模式</h3>
              <p :class="{'is-active': activeDetail === '旁路模式'}">负载均衡设备以旁路模式部署到网络之中，此种模式不会改变客户的网络结构，同时设备上架时不会造成业务的中断，可以实现设备的快速简单部署。</p>
              <button @click="structureDetailClick('旁路模式')">{{activeDetail === '旁路模式'? '←  退出详情': '→  查看详情'}}</button>
            </div>
            <div class="structure-item" :class="{'is-detail': activeDetail === '串行模式'}">
              <div class="icon-deployment"></div>
              <h3>矩尺负载均衡——串行模式</h3>
              <p :class="{'is-active': activeDetail === '串行模式'}">负载均衡设备以路由模式部署到网络之中，此种模式将负载均衡设备串行部署到网络架构中，需要对网络架构进行适配调整，但此种部署方式可以规避源地址转换，从而简化应用溯源的难度。</p>
              <button @click="structureDetailClick('串行模式')">{{activeDetail === '串行模式'? '←  退出详情': '→  查看详情'}}</button>
            </div>
          </div>
        </div>
      </div>
      <!-- 国产化产品支持情况 -->
      <ProductTitle title="国产化产品支持情况" align="left"/>
      <div class="localization-wrap">
        <p>矩尺负载均衡产品使用主流CPU和操作系统，配合自主研发的负载能力，性能足以支撑应用系统的改造，集群部署更能提升整个交付方案的可靠性。目前产品适配统信操作系统及海光处理器，已通过功能及兼容性测试。</p>
      </div>
    </div>
  </div>
</template>

<script>
import ProductTitle from '@/components/ProductTitle.vue'
import TitlePoint from '@/components/TitlePoint.vue'

export default {
  components: {
    ProductTitle,
    TitlePoint
  },
  data() {
    return {
      activeTab: '算法优化',
      activeDetail: '旁路模式',
      characteristicList: [
        {
          title: '提升应用可靠性',
          description: '矩尺负载均衡设备消除了应用单点故障，保障了应用始终可用，可以帮助应用更快捷的进行容量扩展，并为应用虚拟化部署及云服务的实现提供了保障。',
          img: 'reliability'
        },
        {
          title: '提供优质的服务',
          description: '矩尺负载均衡设备全面监控、动态跟踪应用服务性能，提供多种负载均衡策略为应用服务，保证业务可以根据自己的特性和要求始终选择合适的资源。',
          img: 'service'
        },
        {
          title: '保护关键数据',
          description: '矩尺负载均衡设备支持SSL卸载功能，具备强劲的SSL处理能力，支持全面的加密算法配置以及证书管理，不仅提供端到端的SSL加密，保护客户数据，并且可降低应用服务压力，提升应用访问速度。',
          img: 'keydata'
        },
        {
          title: '统一管理和配置',
          description: '矩尺为负载均衡设备提供了统一的管理操作平台，便捷的GUI配置界面，通用的配置场景模板，支持RBAC、资源组分配、设备统一管理、设备批量升级及批量操作。',
          img: 'management'
        },
        {
          title: '应用智能代理',
          description: '矩尺负载均衡设备为用户提供应用全代理服务，支持应用协议版本转换、常用应用协议转换，既可以为应用版本平滑升级提供保障，又可以增强应用的访问兼容性。',
          img: 'agent'
        },
        {
          title: '应用性能智能监控',
          description: '矩尺负载均衡设备可以提供全面应用数据收集，通过基于大数据平台的数据治理及数据展现，深化展现应用性能，为智能化管理和调度应用提供了保障。',
          img: 'monitoring'
        },
        {
          title: '提供应用级防护',
          description: '内嵌应用级防火墙功能，保护Web应用和Web服务免遭应用攻击，帮助企业提供全面的应用防护能力，保护企业的应用和数据安全。',
          img: 'defend'
        },
        {
          title: '开放平台架构',
          description: '矩尺负载均衡设备采用开放平台架构，可以通过API与第三方云平台、操作管理平台、应用性能管理平台、大数据平台、安全运营平台等进行无缝对接。',
          img: 'framework'
        },
        {
          title: '支持云原生环境',
          description: '在云原生环境中，为kubernetes提供Load Balance服务或者Ingress Controller服务，并可在Kubernetes系统中提供Service Mesh服务。',
          img: 'native'
        },
        {
          title: '定制化开发增值服务',
          description: '矩尺网络科技为客户提供基于负载均衡设备的定制化开发服务，帮助用户优化应用场景，提供可持续发展的应用增值服务。',
          img: 'customization'
        }
      ]
    }
  },
  methods: {
    structureDetailClick(name) {
      if (this.activeDetail === name) {
        this.activeDetail = ''
        return
      }
      this.activeDetail = name
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '~@/assets/imgs/sprite.css';
.product-banner{
  height: 300px;
  background-image: url('~@/assets/imgs/product-banner.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px 115px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  text-align: left;
  color: #fff;
  h1 {
    margin-bottom: 10px;
  }
  .title-devide-line{
    width: 155px!important;
  }
  h2 {
    line-height: 100px;
  }
  p {
    margin: 0;
  }
}
.product-body {
  background-color: #fff;
  .product-advantage {
    height: 540px;
    position: relative;
    background-image: url('~@/assets/imgs/product-advantages.jpg');
    background-size: 100% 100%;
    .product-advantage-bgcover{
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba($color: #fff, $alpha: .95);
      padding: 40px 0;
      h1 {
        margin-bottom: 40px;
        .title-point{
          color: $primaryColor;
        }
      }
      .product-advantage-content{
        display: flex;
        &>div{
          flex: 1;
        }
        .product-advantage-content-right {
          text-align: left;
          h3 {
            color: $primaryColor;
            font-weight: 700;
          }
          p {
            margin: 40px 0;
            line-height: 30px;
            text-align: justify;
            letter-spacing: 2px;
          }
        }
      }
    }
  }
  // padding: 30px 100px;
  // display: flex;
}
::v-deep .el-tabs {
  width: 552px;
  .el-tabs__header .el-tabs__item{
    font-size: 15px;
    padding: 0 50px;
  }
  .el-tabs__header .el-tabs__item.is-bottom:nth-child(2){
    padding-left: 0;
  }
  .el-tabs__header .el-tabs__item.is-bottom:last-child{
    padding-right: 0;
  }
  .el-tabs__header.is-bottom .el-tabs__active-bar{
    top: 0;
  }
  .el-tabs__header.is-bottom .el-tabs__nav-wrap::after{
    top: 0;
  }
  .el-tabs__content{
    width: 505px;
    height: 313px;
  }
}
.characteristic {
  width: 1350px;
  height: 1145px;
  margin: 0 auto;
  border: 1px solid #f6b88b;
  border-radius: 4px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  .characteristic-item{
    border-bottom: 1px dotted #aaaaaa;
    flex: 1;
    display: flex;
    align-items: center;
    text-align: left;
    .title-icon{
      width: 64px;
      height: 64px;
      margin: 0 15px;
      transform: scale(86%);
    }
    h2 {
      width: 210px;
      font-size: 22px;
      text-align: left;
      line-height: 46px;
      border-right: 1px dotted #aaaaaa;
      box-sizing: border-box;
    }
    p {
      font-size: 16px;
      padding: 0 32px 0 16px;
      flex: 1;
      text-align: justify;
    }
    &:last-child{
      border-bottom: none;
    }
  }
}
.structure-wrap{
  .structure{
    margin: 0 auto;
    width: 1200px;
    height: 415px;
    background-image: url('~@/assets/imgs/product-deployment-structure.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .structure-bgcover{
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      background-color: rgba(54, 118, 191, .62);
      .structure-item{
        flex: 3;
        height: 100%;
        transition: all .3s;
        padding: 60px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        color: #fff;
        overflow: hidden;
        h3 {
          font-size: 22px;
          line-height: 70px;
        }
        p {
          margin: 0;
          max-height: 0;
          width: 719px;
          line-height: 32px;
          margin-bottom: 16px;
          overflow: hidden;
          transition: all .3s;
          &.is-active{
            max-height: 64px;
          }
        }
        button{
          width: 190px;
          height: 40px;
          font-size: 15px;
          background-color: transparent;
          border: 1px solid #fff;
          border-radius: 2px;
          color: #fff;
          cursor: pointer;
        }
        &:first-child{
          border-right: 1px solid #fff;
        }
      }
      .is-detail {
        flex: 7;
      }
    }
  }
}
.localization-wrap{
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;
  p{
    text-align: justify;
    line-height: 32px;
    margin: 0;
  }
}
</style>